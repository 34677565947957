import React, {useRef, useState} from 'react';
import {Box} from "@mui/system";
import {Form, Modal} from "@douyinfe/semi-ui";
import moment from "moment";
import {Button} from "@mui/material";
import request from "../../utils/request";
import {AgGridReact} from "ag-grid-react";
import {toast} from "react-toastify";

const SYQNoSalesUserRecord = () => {
    const initData = JSON.parse(localStorage.getItem('initData'))
    const loginuser = JSON.parse(localStorage.getItem('userinfo'))
    const [list,setlist] = useState([])
    const [department,setdepartment] = useState([])
    const [selectedRows, setselectedRows] = useState([])
    const [open, setopen] = useState(false)
    const [attributiondepartmentid, setattributiondepartmentid] = useState(0)
    const onSelectionChanged = e => {
        const arr = gridRef.current.api.getSelectedRows();
        setselectedRows(arr)
    }
    const gridRef = useRef()
    const api = useRef()

    return (
        <Box>
            <Box fontSize={18} mb={3}>商用气无销售用户记录</Box>
            <Form layout={'horizontal'} labelPosition={'inset'} onSubmit={async e=> {
                const rew = await request('post','/api/getInfo',{
                    url:'Srapp.Web_Report_Manage_Infos.SYQNoSalesUserRecord',
                    ...e,
                    attributiondepartment: JSON.stringify(e.attributiondepartment),
                    salesman: JSON.stringify([e.salesman]),
                })
                // console.log(rew.data
                setlist(rew.data.info)
            }}>
                {/*begintime	日期	必须			开始时间*/}
                {/*endtime	日期	必须			结束时间*/}
                {/*memberid	字符串	可选			会员号*/}
                {/*attributiondepartment	字符串	必须			归属部门 JSON ["商用气开发一部","商用气开发二部"]*/}
                {/*salesman	字符串	可选			业务员 JSON ["张三","李四"]*/}
                <Form.Input field={'date'} label={'日期'} type={'date'} initValue={moment().format('YYYY-MM-DD')}/>
                <Form.Select rules={[{required: true}]} onChange={e=>setdepartment(e)} multiple filter maxTagCount={1} style={{width:300}} field={'attributiondepartment'} label={'归属部门'}>
                    {
                        initData.DepartmentList.filter(item=>item.name.indexOf('商用气') != -1)
                        .map(item=><Form.Select.Option key={item.id} value={item.id}>{item.name}</Form.Select.Option>)
                    }
                </Form.Select>
                {/*salesman	字符串	可选			业务员 JSON ["张三","李四"]*/}
                <Form.Select rules={[{required: true}]}  filter maxTagCount={1} style={{width:300}} field={'salesman'} label={'业务员'}>
                    {
                        initData.OperatorList.filter(item=> {
                            if(department.length == 0) return true
                            return department.indexOf(item.departmentid) != -1
                        })
                        .map(item=><Form.Select.Option key={item.id} value={item.name}>{item.name}</Form.Select.Option>)
                    }
                </Form.Select>


                <Button variant={'contained'} size={'small'} type={'submit'}>查询</Button>
                <Button sx={{ ml: 2 }}  onClick={()=>{
                    if (!selectedRows.length) {
                        toast.error('请勾选用户')
                        return
                    }
                    setopen(true)
                }} variant={'contained'} size={'small'} type={'button'}>修改用户部门</Button>

            </Form>

            <Modal visible={open} onCancel={() => setopen(false)} style={{ top: 100 }} footer={<></>} >
                {
                    open ?
                        <Form getFormApi={e => {
                            api.current = e
                        }} onSubmit={async e => {
                            const rew = await request('post', '/api/getInfo', {
                                url: 'Srapp.Web_User_EditInfo.BatchChangeUserInfo',
                                ...e,
                                userids: JSON.stringify(selectedRows?.map(item => item.userid))
                            })
                            if (rew.data.msg === 'SUCCESS') {
                                toast.success('操作成功')
                            } else {
                                toast.error(`操作失败 ${rew.data.tips}`)
                            }
                            setopen(false)

                        }}>
                            <Form.Select onChange={e => {
                                console.log(e)
                                setattributiondepartmentid(e)
                            }} field={'attributiondepartmentid'} filter label={'归属部门'} style={{ width: '100%' }}>
                                {
                                    initData.DepartmentList.filter(item => item.name.includes('商'))?.map(item => <Form.Select.Option
                                        value={item.id}>{item.name}</Form.Select.Option>)
                                }
                            </Form.Select>

                            <Form.Select filter field={'salesmanopeid'} label={'业务员'} style={{ width: '100%' }}>
                                {
                                    initData?.OperatorList.filter(item => item.department.includes('商'))?.map(item =>
                                        <Form.Select.Option
                                            value={item.opeid}>{item.name}</Form.Select.Option>)
                                }
                            </Form.Select>
                            <Form.Input field={'remarks'} label={'备注'} />
                            <Button sx={{ mt: 2 }} type={"submit"} variant={"contained"}>确认修改</Button>
                        </Form> : ''
                }
            </Modal>

            <Box height={'60vh'} overflow={'scroll'} mt={3}>
                <AgGridReact
                    className="ag-theme-balham"
                    rowData={list}
                    ref={gridRef}
                    onSelectionChanged={onSelectionChanged}
                    rowSelection="multiple"
                    columnDefs={[

                        // {
                        //     "memberid": "841326",
                        //     "addtime": "2021-12-03 15:09:04.437",
                        //     "name": "钟恩明",
                        //     "telephone": "18076459712",
                        //     "workplace": "诚信猪肉档",
                        //     "address": "便民鲜市场B06号诚信猪肉档",
                        //     "customertypeid": "2",
                        //     "attributiondepartmentid": "90",
                        //     "lasttransactiontime": "2024-01-16 10:41:14.000",
                        //     "customertype": "商业用户",
                        //     "attributiondepartment": "商用气开发一部"
                        // }
                        {field: 'addtime', headerName: '开户时间',headerCheckboxSelection: true,
                            headerCheckboxSelectionFilteredOnly: true,
                            checkboxSelection: true },
                        {field: 'memberid', headerName: '会员号', },
                        {field: 'name', headerName: '姓名', },
                        {field: 'telephone', headerName: '电话', },
                        {field: 'workplace', headerName: '工作单位', },
                        {field: 'address', headerName: '地址', },
                        {field: 'customertype', headerName: '客户类型', },
                        {field: 'attributiondepartment', headerName: '归属部门', },
                        {field: 'lasttransactiontime', headerName: '最后交易时间', },

                    ]}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    }}
                />
            </Box>

        </Box>
    );
};

export default SYQNoSalesUserRecord;
