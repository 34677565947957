import React, { useEffect, useRef, useState } from 'react';

import './SoftphoneComponent.css';
import {useNavigate} from "react-router-dom"; // 引入自定义的CSS文件

const SoftphoneComponent = ({acceptCall,softphoneRef,setShow,setPhoneNumber,getStatusClass,seatStatus,changeSeatStatus,phoneNumber,makeCall,incomingCall,callHistory}) => {

    const navigate = useNavigate()
    return (
        <div className={'softphoneroom'}>


        <div className={`softphone-container ${getStatusClass()}`}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <h1>软电话坐席</h1>
                <div onClick={()=>{
                    setShow(false)
                }}>关闭</div>
            </div>
            <div className="seat-status">
                <label>切换坐席状态：</label>
                <select value={seatStatus} onChange={(e) => changeSeatStatus(e.target.value)}>
                    <option value="0">空闲</option>
                    <option value="1">忙碌</option>
                    <option value="2">小休</option>
                </select>
            </div>

            <div className="phone-controls">
                <input
                    type="text"
                    placeholder="输入电话号码"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <button onClick={makeCall} disabled={!phoneNumber}>
                    外呼
                </button>
                <button onClick={() => softphoneRef.current.callApi.hangup({
                    success: () => console.log('挂断成功'),
                    fail: (err) => console.error('挂断失败', err)
                })}>
                    挂断
                </button>
                {incomingCall && (
                    <button onClick={acceptCall}>接听电话</button>
                )}
                {incomingCall && (
                    <button onClick={()=>{
                        navigate('/users/VagueQueryUserInfo?tel=' + phoneNumber)
                    }}>跳转查询</button>
                )}
            </div>

            <h2>通话记录</h2>
            <div className="call-history">
                {callHistory.length === 0 ? (
                    <p>暂无通话记录</p>
                ) : (
                    <ul>
                        {callHistory.map((record, index) => (
                            <li key={index}>
                                <span>{record.time}</span> -
                                <span>{record.type}</span> -
                                <span>从 {record.from}</span> -
                                <span>到 {record.to}</span> -
                                <a href={record.monitor} target="_blank" rel="noopener noreferrer">录音</a>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
        </div>
    );
};

export default SoftphoneComponent;