import React, {useEffect} from 'react';
import { Button } from "@mui/material";
import copy from "copy-to-clipboard";
import request from "../../../utils/request";
// import { SheetComponent,TableSheet } from '@antv/s2-react';
const UserInfo = ({ userinfo }) => {
    // console.log(userinfo)
    const [list, setList] = React.useState([])
    useEffect(async ()=>{
        //获取用户地址信息
        if (userinfo) {
            const rew = await request('post','/api/getInfo',{
                url:"Srapp.Web_User_Infos.UserAddress",
                userid: userinfo.userid
            });
            setList(rew.data)
        }

    },[userinfo])
    const copymsg = (text) => {
        copy(text)
    }
    // const s2Options = {
    //     width: 600,
    //     height: 300,
    //     // showSeriesNumber: true,
    //     // 通过配置 conditions 实现字段标记
    //     conditions: {
    //         text: [
    //             {
    //                 field: "price",
    //                 mapping(fieldValue, data) {
    //                     return {
    //                         // fill 是文本字段标记下唯一必须的字段，用于指定文本颜色
    //                         fill: "#5B8FF9",
    //                     };
    //                 },
    //             },
    //         ],
    //         background: [],
    //         interval: [],
    //         icon: []
    //     },
    //     tooltip: {
    //         operation: {
    //             trend: true,
    //         },
    //     },
    //     showSeriesNumber: true,
    //     frozenRowCount: 1, // 行头冻结数量
    //     frozenColCount: 1, // 列头冻结数量
    //     frozenTrailingRowCount: 1, // 列尾冻结数量
    //     frozenTrailingColCount: 1, // 列尾冻结数量
    //     interaction: {
    //         linkFields: [
    //             'price',
    //         ],
    //     },
    // }
    // const s2DataConfig = {
    //     fields: {
    //         columns: ['province', 'city', 'type', 'price','操作'],
    //     },
    //     meta: [
    //         {
    //             field: 'province',
    //             name: '省份',
    //         },
    //         {
    //             field: 'city',
    //             name: '城市',
    //         },
    //         {
    //             field: 'type',
    //             name: '商品类别',
    //         },
    //         {
    //             field: 'price',
    //             name: '价格',
    //         },
    //
    //     ],
    //     data: [
    //         {
    //             province: "浙江",
    //             city: "杭州",
    //             type: "笔",
    //             price: "1",
    //         },
    //         {
    //             province: "浙江",
    //             city: "杭州",
    //             type: "笔",
    //             price: "2",
    //         },
    //         {
    //             province: "浙江",
    //             city: "杭州",
    //             type: "纸张",
    //             price: "2",
    //         },
    //         {
    //             province: "浙江",
    //             city: "舟山",
    //             type: "笔",
    //             price: "17",
    //         },
    //         {
    //             province: "浙江",
    //             city: "舟山",
    //             type: "纸张",
    //             price: "6",
    //         },
    //         {
    //             province: "吉林",
    //             city: "长春",
    //             type: "笔",
    //             price: "8",
    //         },
    //         {
    //             province: "吉林",
    //             city: "白山",
    //             type: "笔",
    //             price: "12",
    //         },
    //         {
    //             province: "吉林",
    //             city: "长春",
    //             type: "纸张",
    //             price: "3",
    //         },
    //         {
    //             province: "吉林",
    //             city: "白山",
    //             type: "纸张",
    //             price: "25",
    //         },
    //         {
    //             province: "浙江",
    //             city: "杭州",
    //             type: "笔",
    //             cost: "0.5",
    //         },
    //         {
    //             province: "浙江",
    //             city: "杭州",
    //             type: "纸张",
    //             cost: "20",
    //         },
    //         {
    //             province: "浙江",
    //             city: "舟山",
    //             type: "笔",
    //             cost: "1.7",
    //         },
    //         {
    //             province: "浙江",
    //             city: "舟山",
    //             type: "纸张",
    //             cost: "0.12",
    //         },
    //         {
    //             province: "吉林",
    //             city: "长春",
    //             type: "笔",
    //             cost: "10",
    //         },
    //         {
    //             province: "吉林",
    //             city: "白山",
    //             type: "笔",
    //             cost: "9",
    //         },
    //         {
    //             province: "吉林",
    //             city: "长春",
    //             type: "纸张",
    //             cost: "3",
    //         },
    //         {
    //             province: "吉林",
    //             city: "白山",
    //             type: "纸张",
    //             cost: "1",
    //         }
    //     ]
    // };
    return (
        <>
            {/*<TableSheet*/}
            {/*    dataCfg={s2DataConfig}*/}
            {/*    options={s2Options}*/}
            {/*    sheetType="table"*/}
            {/*/>*/}
            <table className="tableuser">
                <tbody>
                    <tr>
                        <th>会员号</th>
                        <th><Button p={0} onClick={() => copymsg(userinfo.memberid)}
                            sx={{ color: "#000" }}>{userinfo.memberid}</Button></th>
                        <th>姓名</th>
                        <th><Button p={0} onClick={() => copymsg(userinfo.name)}
                            sx={{ color: "#000" }}>{userinfo.name}</Button></th>
                        <th>单位</th>
                        <th>
                            <Button p={0} onClick={() => copymsg(userinfo.workplace)}
                                sx={{ color: "#000" }}>{userinfo.workplace}</Button>
                        </th>

                    </tr>
                    <tr>
                        <th>
                            电话
                        </th>
                        <th>
                            <Button p={0} onClick={() => copymsg(userinfo.telephone)}
                                sx={{ color: "#000" }}>{userinfo.telephone}</Button>
                        </th>
                        <th>
                            信用额度
                        </th>
                        <th>
                            <Button p={0} onClick={() => copymsg(parseFloat(userinfo.quota).toFixed(2))}
                                sx={{ color: "#000" }}>{parseFloat(userinfo.quota).toFixed(2)}</Button>

                        </th>
                        <th>
                            发卡点
                        </th>

                        <th>
                            <Button p={0} onClick={() => copymsg(userinfo.department)}
                                sx={{ color: "#000" }}>{userinfo.department}</Button>

                        </th>

                    </tr>
                    <tr>
                        <th>类型</th>
                        <th><Button p={0} onClick={() => copymsg(userinfo.customertype)}
                            sx={{ color: "#000" }}>{userinfo.customertype}</Button></th>
                        <th>开户时间</th>
                        <th>
                            <Button p={0} onClick={() => copymsg(userinfo.addtime)}
                                sx={{ color: "#000" }}>{userinfo?.addtime?.substr(0, 10) ?? ''}</Button>
                        </th>
                        <th>专项款</th>
                        <th><Button p={0} onClick={() => copymsg(parseFloat(userinfo.cashgiftbalance).toFixed(2))}
                            sx={{ color: "#000" }}>{parseFloat(userinfo.cashgiftbalance).toFixed(2)}</Button>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            余额
                        </th>
                        <th>

                            <Button p={0} onClick={() => copymsg(userinfo.balance)}
                                sx={{ color: "#000" }}><span style={{ color: 'red' }}>{parseFloat(userinfo.balance).toFixed(2)}</span></Button>
                        </th>

                        <th>
                            住所类型
                        </th>
                        <th>
                            <Button p={0} onClick={() => copymsg(userinfo.housingproperty)}
                                sx={{ color: "#000" }}>{userinfo.housingproperty}</Button>

                        </th>
                        <th>
                            开户业务员
                        </th>
                        <th>
                            <Button p={0} onClick={() => copymsg(userinfo.developsalesman)}
                                sx={{ color: "#000" }}>{userinfo.developsalesman}</Button>

                        </th>


                    </tr>
                    <tr>

                        <th>用户等级</th>
                        <th><Button p={0} onClick={() => copymsg(userinfo.viplevel)}
                            sx={{ color: "#000" }}>{userinfo.viplevel}</Button></th>
                        <th>归属部门</th>

                        <th><Button p={0} onClick={() => copymsg(userinfo.attributiondepartment)}
                            sx={{ color: "#000" }}>{userinfo.attributiondepartment}</Button>
                        </th>
                        <th>发卡人</th>
                        <th>
                            <Button p={0} onClick={() => copymsg(userinfo.operator)}
                                sx={{ color: "#000" }}>{userinfo.operator}</Button>
                        </th>
                    </tr>
                    <tr>

                        <th>
                            状态
                        </th>
                        <th>
                            <Button p={0} onClick={() => copymsg(userinfo.state)}
                                sx={{ color: "#000" }}>{userinfo.state}</Button>
                        </th>
                        <th>
                            维护业务员

                        </th>

                        <th>
                            <Button p={0} onClick={() => copymsg(userinfo.salesman)}
                                sx={{ color: "#000" }}>{userinfo.salesman}</Button>

                        </th>

                        <th>
                            备注
                        </th>

                        <th>
                            <Button p={0} onClick={() => copymsg(userinfo.remarks)}
                                sx={{ color: "#000" }}>{userinfo.remarks}</Button>
                        </th>

                    </tr>
                    <tr>
                        <th>积分</th>
                        <th><Button p={0} onClick={() => copymsg(userinfo.integral)}
                                           sx={{ color: "#000" }}>{userinfo.integral}</Button></th>
                        <th >地址信息</th>
                        <th colSpan={3}>
                            <select style={{
                                fontSize: 16,
                                border: "none",
                                padding: '2px'
                            }}>
                                {
                                    list.filter(item=>item.state === '正常').map(item=>
                                    <option value={item.address}>{item.address}</option>
                                    )
                                }
                            </select>
                            {/*<Button p={0} onClick={() => copymsg(userinfo.address)}*/}
                            {/*        sx={{ color: "#000" }}>{userinfo.address}</Button>*/}
                        </th>
                    </tr>


                </tbody>
            </table>
        </>


    );
};

export default UserInfo;
