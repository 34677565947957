// agentConfig.js
const agentConfig = {
    110: {
        accountId: 'N00000062988',
        agentNumber: '8000@sryhq',
        password: 'Bh8D6zrL4z8000'
    },
    102: {
        accountId: 'N00000062989',
        agentNumber: '8001@sryhq',
        password: 'Ah7D3xrJ2z8001'
    },
    119: {
        accountId: 'N00000062990',
        agentNumber: '8002@sryhq',
        password: 'Ch9E4ytK5z8002'
    },
    999: {
        accountId: 'N00000062988',
        agentNumber: '8000@sryhq',
        password: 'Bh8D6zrL4z8000',
    },
    // 添加更多的座席配置
};

module.exports = agentConfig;