import React from 'react';
import {Box} from "@mui/system";
import {Form, Image, Modal} from "@douyinfe/semi-ui";
import moment from "moment";
import {Button} from "@mui/material";
import request from "../../../utils/request";
import {AgGridReact} from "ag-grid-react";

const UserSecurityInspectionRecord = ({userinfo}) => {
    const [list,setList] = React.useState([])
    return (
        <div>
            <Box >

                <Form layout={'horizontal'} labelPosition={'inset'} onSubmit={async e=>{
                    const rew = await request('post','/api/getInfo',{
                        url: 'Srapp.Web_User_Infos.UserSecurityInspectionRecord',
                        ...e,
                        userid:userinfo.userid
                    })
                    setList(rew.data)
                }}>
                    {/*begintime	日期	必须			录入开始时间*/}
                    {/*endtime	日期	必须			录入结束时间*/}
                    {/*type	枚举类型	必须		范围：全部/供气合同	用户资料类型*/}
                    {/*<Form.Input field={'begintime'} label={'开始时间'} type={'date'} initValue={moment().format('YYYY-MM-DD')}/>*/}
                    {/*<Form.Input field={'endtime'} label={'结束时间'} type={'date'} initValue={moment().format('YYYY-MM-DD')}/>*/}
                    {/*<Form.Select field={'type'} initValue={'全部'} label={'用户资料类型'} style={{width: 300}}>*/}
                    {/*    <Form.Select.Option value={'全部'}>全部</Form.Select.Option>*/}
                    {/*    <Form.Select.Option value={'供气合同'}>供气合同</Form.Select.Option>*/}
                    {/*</Form.Select>*/}

                    <Button variant={'contained'} type={'submit'}>搜索</Button>
                </Form>


                <Box mt={3} overflow={'scroll'} height={'60vh'}>
                    <AgGridReact
                        className={'ag-theme-balham'}
                        rowData={list}
                        columnDefs={[
                            // "[#1 - 2.05ms - SQL]/www/wwwroot/suda-2-api/src/srapp/Domain/User/Info.php(1713):    Srapp\\Model\\Curd\\QueryAction::QueryTable()    user_securityinspectionrecords    SELECT id,addtime,userid,memberid,name,telephone,workplace,province,city,area,town,address,housingproperty,customertype,attributiondepartment,salesman,developsalesman,detail,imglist,remarks,department,operator,state FROM user_securityinspectionrecords WHERE (companyid = '101') AND (userid = '267817');"                            {field: 'id', headerName: 'id'},
                            {field: 'addtime', headerName: '添加时间'},

                            {field: 'name', headerName: '姓名'},
                            {field: 'telephone', headerName: '电话'},
                            {field: 'workplace', headerName: '工作单位'},

                            {field: 'address', headerName: '详细地址'},
                            // {field: 'housingproperty', headerName: '房屋性质'},
                            {field: 'customertype', headerName: '用户类型'},
                            {field: 'attributiondepartment', headerName: '归属部门'},
                            {field: 'salesman', headerName: '业务员'},
                            {field: 'developsalesman', headerName: '开发业务员'},
                            {field: 'detail', headerName: '检查详情'},
                            {field: 'remarks', headerName: '备注'},
                            {field: 'department', headerName: '部门'},
                            {field: 'operator', headerName: '操作人'},
                            {field: 'state', headerName: '状态'},

                            {field: 'imglist',pinned:'right', headerName: '图片列表',cellRendererFramework: ({data}) => <Button
                                    onClick={async ()=>{
                                        let arr = []
                                        data.imglist.map(item=>{
                                            // item.imgids [1,2,3]
                                            arr = [...arr,...item.imgids]
                                        })
                                        const rew = await request('post', '/api/getInfo', {
                                            url: 'Srapp.Action.GetImgList',
                                            id: JSON.stringify(arr)
                                        })
                                        // [
                                        // {
                                        //     "imgurl": "https://nnsrosstest.oss-rg-china-mainland.aliyuncs.com/4DCBD3B226B939556A51DB1655E0225A.jpg",
                                        //     "imgtype": "用户资料",
                                        //     "id": "1028459"
                                        // },
                                        //     {
                                        //         "imgurl": "https://nnsrosstest.oss-rg-china-mainland.aliyuncs.com/56B80BA307C7D8BFE20979C77A7252B3.jpg",
                                        //         "imgtype": "用户资料",
                                        //         "id": "1028470"
                                        //     }
                                        // ]

                                        let str = ''
                                        Modal.info({
                                            title: '图片列表',
                                            content: <div>
                                                {rew.data.map(item => {
                                                    return <Box>
                                                        <Box fontSize={18} mb={2}>{item.imgtype}</Box>
                                                        <Image src={item.imgurl}
                                                               width={300}
                                                               height={450}/>
                                                    </Box>

                                                })}
                                            </div>,
                                            onOk: () => {
                                            },

                                        })

                                    }}


                                >点击查看</Button>

                            },


                        ]}
                        defaultColDef={{
                            resizable: true,
                            sortable: true,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        }}
                        onFirstDataRendered={(params) => {
                            params.api.sizeColumnsToFit();
                        }}
                    />
                </Box>
            </Box>
        </div>
    );
};

export default UserSecurityInspectionRecord;
