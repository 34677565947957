import React, {useState} from 'react';
import {Box, Button} from "@mui/material";
import {Form} from "@douyinfe/semi-ui";
import moment from "moment";
import request from "../../utils/request";
import {AgGridReact} from "ag-grid-react";

const RZBOrderStatistics = () => {
    const initData = JSON.parse(localStorage.getItem('initData'))
    const loginuser = JSON.parse(localStorage.getItem('userinfo'))
    const new_department_byname = JSON.parse(localStorage.getItem('new_department_byname'))
    const [list, setList] = useState([])
    return (
        <Box p={3} borderRadius={1}>
            <Box fontSize={18} mb={3}>人资部液化气订单统计</Box>
            <Form layout={'horizontal'} labelPosition={'inset'} onSubmit={async e=>{
                const rew =await request('post','/api/getInfo',{
                    url:'Srapp.Web_Report_Manage_Infos.RZBOrderStatistics',
                    ...e,
                    department:JSON.stringify(e.department),
                })
                setList(rew.data.info)
            }}>
                <Form.Input field={'begintime'} label={'开始时间'} type={'date'} initValue={moment().format('YYYY-MM-DD')} />
                <Form.Input field={'endtime'} label={'结束时间'} type={'date'} initValue={moment().format('YYYY-MM-DD')} />
                <Button variant={'outlined'} size={'small'} type={'submit'}>查询</Button>
            </Form>

            <Box mt={3} height={'60vh'} overflow={'scroll'}>
                <AgGridReact
                    className={'ag-theme-balham'}
                    columnDefs={[
                        // {
                        //     "department": "爱华店",
                        //     "goodsname": "12KG液化气",
                        //     "wxjtmode": "8",
                        //     "wxsymode": "32",
                        //     "yyzxmode": "38",
                        //     "zdmode": "4",
                        //     "ctimode": "6",
                        //     "jt": "43.0",
                        //     "sy": "53.0"
                        // }
                        // 门店	商品	微信家庭订单数	微信商业订单数	预约	站点	电话	家庭销量	商业销量
                        {field: 'department', headerName: '门店', flex: 1},
                        {field: 'goodsname', headerName: '商品', flex: 1},
                        {field: 'wxjtmode', headerName: '微信家庭订单数', flex: 1},
                        {field: 'wxsymode', headerName: '微信商业订单数', flex: 1},
                        {field: 'yyzxmode', headerName: '预约', flex: 1},
                        {field: 'zdmode', headerName: '站点', flex: 1},
                        {field: 'ctimode', headerName: '电话', flex: 1},
                        {field: 'jt', headerName: '家庭销量', flex: 1},
                        {field: 'sy', headerName: '商业销量', flex: 1},



                    ]}
                    rowData={list}
                    defaultColDef={{
                        sortable: true,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                        resizable: true,
                        flex: 1,



                    }}
                />
            </Box>
        </Box>
    );
};

export default RZBOrderStatistics;
